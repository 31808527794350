export const {
    VUE_APP_NAME:APPNAME,
    VUE_APP_URL:APPURL,
    VUE_APP_API_URL:APPAPIBASEURL,
    VUE_APP_SENTRY_DNS:SENTRYDNS,
    VUE_APP_RELEASE:APPRELEASE,
    VUE_APP_MODE:APPMODE,
    VUE_APP_GEOCODINGURL:GEODINGURL,
    VUE_APP_GOOGLE_APIKEY:GOOGLEAPIKEY,
    VUE_APP_OUR_EMAIL:CONTACTEMAIL,
} = import.meta.env;