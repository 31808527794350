import 'vuetify/styles'
import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css'



export default createVuetify(
    {icons: {
        defaultSet: 'mdi',
      },
    }
);
